import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import { IoClose, IoReorderThreeOutline } from "react-icons/io5";
import { RxTriangleDown } from "react-icons/rx";
import DashboardLogo from "../../assets/image-components/DashboardLogo";
import LeadsLogo from "../../assets/image-components/LeadsLogo";
import Logo from "../../assets/images/logo.png";
import Logo_mobile_view from "../../assets/images/logo_mobile_view.png"
import Logo1 from "../../assets/images/logo1.png";
import Logo1_mobile_view from "../../assets/images/logo1_mobile_view.png"

function Header() {
  const location = useLocation();
  const [path, setPath] = useState("");
  const [title, setTitle] = useState("Dashboard");
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/core-routes/dashboard") {
      setTitle("DashBoard");
      setPath("/core-routes/dashboard");
    } else if (location.pathname === "/core-routes/leads") {
      setTitle("Leads");
      setPath("/core-routes/leads");
    } else if (location.pathname === "/core-routes/settings") {
      setTitle("Settings");
      setPath("/core-routes/settings");
    }
  }, [location.pathname]);

  const [showSidebar, setShowSidebar] = useState(false);

  // useEffect(() => {
  //   const navbar = document.getElementById("navbarSupportedContent");
  //   navbar.addEventListener("hidden.bs.collapse", () => {
  //     setNavbarCollapsed(true);
  //   });
  //   navbar.addEventListener("shown.bs.collapse", () => {
  //     setNavbarCollapsed(false);
  //   });
  // }, []);
  useEffect(() => {
    if (location.pathname.includes("/core-routes/leads/chat/")) {
      setShowSidebar(false);
    }
  }, [location.pathname]);

  return (
    <nav
      className="navbar navbar-expand-md header-container mx-1"
      // style={{ borderBottom: "1px solid #000000" }}
    >
      <div className="0 d-flex justify-content-between">
        <div className=" align-items-center employee-list-header-text">
          <span
            className="d-none d-md-block header-title-text-wrap"
            onClick={() => {
              if (title === "Dashboard") {
                navigate("/core-routes/dashboard");
              } else if (title === "Leads") {
                navigate("/core-routes/leads");
              }
            }}
            style={{
              cursor: "pointer",
            }}
          ></span>
          <p className='header-title'>
            {title === "Dashboard"
              ? "Dashboard"
              : title === "Leads"
                ? "Leads"
                : title === "Settings"
                  ? "Settings"
                  : null}
          </p>
        </div>
        {!location.pathname.includes('/core-routes/leads/chat') &&
        <div
        className="d-md-none ps-2 d-flex col-7 header-title-text-con "
        style={{ height: "100%" }}
      >
        <div
          className="d-flex align-items-center"
          onClick={() => {
            setShowSidebar(true);
          }}
        >
          <div className="sidebar-logo-container-header">
            {/* <img src={Logo} alt="logo1" className="sidebar-logo1"/> */}
            <img src={Logo_mobile_view} alt="logo1" className="sidebar-logo1" style={{height:'40px',width:'33px'}} />
            {/* <img src={Logo1} alt="logo2" className="sidebar-logo2" /> */}
            <img src={Logo1_mobile_view} alt="logo2" className="sidebar-logo2"  style={{height:'40px',width:'50px'}} />
          </div>
          <div className="d-flex align-items-center gap-1">
            <RxTriangleDown className="header-dropdown-icon" />
          </div>
        </div>
      </div>
        }
        

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {path === "dashboard" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-md-5">
              <NavLink
                to={"/core-routes/dashboard"}
                className="nav-link ms-2 ms-md-0 active"
              >
                Dashboard
              </NavLink>
            </ul>
          )}
        </div>
      </div>

      {showSidebar && (
        <div className="header-sidebar-main">
          <div className="d-flex justify-content-end">
            {/* <span className="header-title-text"></span> */}

            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              type="button"
            >
              {!showSidebar && (
                <span>
                  <IoReorderThreeOutline className="toggle-icon" />
                </span>
              )}
              {showSidebar && (
                <span
                  onClick={() => {
                    setShowSidebar(false);
                  }}
                >
                  <IoClose className="toggle-icon" />
                </span>
              )}
            </button>
          </div>

          <div className="sidebar-list-container">
            <NavLink
              className="sidebar-list-inner-container"
              to={"dashboard"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                    backgroundColor: "#ffffff",
                    color: "#000000",
                  }
                  : { color: "#ffffff" }
              }
            >
              <div className="logo-hide-head">
                <DashboardLogo color={"#FFFFFF"} style={{color:'red'}}/>
              </div>
              <div className="logo-show-head">
                <DashboardLogo color={"#283557"} style={{color:'red'}}/> 
              </div>
              <span className="sidebar-list-text">Dashboard</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"leads"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#ffffff", color: "#000000" }
                  : { color: "#ffffff" }
              }
            >
              <div className="logo-hide-head">
                <LeadsLogo color={"#FFFFFF"} />
              </div>
              <div className="logo-show-head">
                <LeadsLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Leads</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"settings"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#ffffff", color: "#000000" }
                  : { color: "#ffffff" }
              }
            >
              <div className="logo-hide-head">
                <LeadsLogo color={"#FFFFFF"} />
              </div>
              <div className="logo-show-head">
                <LeadsLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Settings</span>
            </NavLink>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Header;
