import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MODULE_URLS } from "../../constants/module-urls";
import "../Settings.css";
import AddOtherDetails from "./AddOtherDetails";
import axios from "axios";
import { MdOutlineDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function ListOtherDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [listOtherData, setListOtherData] = useState(null);
  const getTableData = () => {
    axios.get(`${MODULE_URLS.GET_ALL_OTHER_DETAILS}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data;
        const rowDataWithSNo = responseData.map((rowData, index) => ({
          ...rowData,
          sNo: index + 1,
        }));
        setListOtherData(rowDataWithSNo);
      }
    });
  };
  useEffect(() => {
    getTableData();
  }, [location.pathname]);

  const handleAlert = (responseId) => {
    Swal.fire({
      title: "Confirm Delete",
      html: "Are you sure you want to Delete this details?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(responseId);
      }
    });
  };

  const handleDelete = (responseId) => {
    axios
      .delete(`${MODULE_URLS.DELETE_OTHER_DETAILS_BY_ID}/${responseId}`)
      .then((response) => {
        if (response.status === 200) {
          getTableData();
          toast.success("Details Deleted Successfully");
        } else {
          throw new Error("Failed to delete");
        }
      })
      .catch((error) => {
        console.error("Error deleting:", error);
      });
  };

  const colDefs = [
    { field: "sNo", headerName: "S No" },
    { field: "responseText", headerName: "Other Details" },
    {
      headerName: "Actions",
      cellRenderer: (params) => {
        console.log("Params in cellRenderer:", params);
        return (
          <span
            className="delete-icon"
            title="Delete"
            onClick={() => handleAlert(params.data.responseId)}
          >
            <MdOutlineDelete size={20} />
          </span>
        );
      },
      width: 100,
      suppressMenu: true,
    },
  ];
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  return (
    <div className="dashboard-component">
      <div className="profile-container-query-chat-screen">
        <div className="profile-container">
          <div className="profile-name-container-query-chat-screen">
            <span className="profile-name-text-query-chat-screen">
              Other Details
            </span>
          </div>
        </div>
        <div className="writing-parameter-list-content-inner-text">
          <button
            className="list-add-bttn"
            style={{ height: "auto" }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            {" "}
            Add New Details
          </button>
        </div>
      </div>

      <div
        className="dashboard-table-content"
        style={{ width: "100%", height: "calc(100vh - 200px)" }}
      >
        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: "100%" }}
        >
          <AgGridReact
            rowData={listOtherData}
            columnDefs={colDefs}
            gridOptions={gridOptions}
          />
        </div>
      </div>
      <AddOtherDetails
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        // staffEdit={staffData}
      />
    </div>
  );
}

export default ListOtherDetails;
