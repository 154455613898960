class SessionStorageService {
  PERMISSIONS_KEY = "Permissions";
  TOKEN_KEY = "token";

  setToken(token) {
    sessionStorage.setItem(this.TOKEN_KEY, token);
  }
  getToken() {
    return sessionStorage.getItem(this.TOKEN_KEY);
  }

  removeToken() {
    sessionStorage.removeItem(this.TOKEN_KEY);
  }
  isUserLoggedIn() {
    const token = sessionStorage.getItem(this.TOKEN_KEY);
    return token !== null && token.startsWith("Bearer");
  }
  isLoading(loading) {
    sessionStorage.setItem("loading", loading);
  }

  getLoading() {
    return sessionStorage.getItem("loading");
  }
}
const sessionService = new SessionStorageService();
export default sessionService;
