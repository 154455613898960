import React, { useEffect, useState } from "react";
import ListOtherDetails from "./other-details/ListOtherDetails";
import ListDefaultAnswers from "./default-answers/ListDefaultAnswers";
import KeywordList from "./keyword-response/KeywordList";
import { useData } from "../../DataProvider";

function SettingsLookupNavbar() {
  const [activeTab, setActiveTab] = useState("DefaultAnswers");

  const { data, setData } = useData();

  useEffect(() => {
    sessionStorage.setItem("currentPageIndex", "");
    sessionStorage.setItem("dashboardScrollPosition", "");
    setData(null);
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="sticky-header flex-sm-row gap-2" style={{ top: 0 }}>
        <div className="d-flex align-items-center gap-1">
          <div className="d-flex gap-1 ps-1">
            <span
              className={`settings-lookup-navbar-text ${
                activeTab === "DefaultAnswers" &&
                "settings-lookup-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("DefaultAnswers");
              }}
            >
              Default Answers
            </span>
            <span
              className={`settings-lookup-navbar-text ${
                activeTab === "OtherDetails" &&
                "settings-lookup-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("OtherDetails");
              }}
            >
              Other Details
            </span>
            <span
              className={`settings-lookup-navbar-text ${
                activeTab === "Keyword" && "settings-lookup-navbar-text-active"
              }`}
              onClick={() => {
                setActiveTab("Keyword");
              }}
            >
              Keyword
            </span>
          </div>
        </div>
      </div>
      <div>
        {activeTab === "DefaultAnswers" && (
          <>
            <ListDefaultAnswers />
          </>
        )}
        {activeTab === "OtherDetails" && (
          <>
            <ListOtherDetails />
          </>
        )}
        {activeTab === "Keyword" && (
          <>
            <KeywordList />
          </>
        )}
      </div>
    </div>
  );
}

export default SettingsLookupNavbar;
