import "./Dashboard.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import userIcon from "../../assets/images/user 3 2.png";
import Briefcase from "../../assets/images/briefcase 1.png";
import subtract from "../../assets/images/Subtract.png";
import tick from "../../assets/images/Subtract (1).png";
import { IoIosSend } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MODULE_URLS } from "../constants/module-urls";
import { ImTruck } from "react-icons/im";
import { isDesktop } from "react-device-detect";
import { BsCashCoin } from "react-icons/bs";
import axios from "axios";
import { useData } from "../../DataProvider";
import { CiSearch } from "react-icons/ci";

function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef(null);
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardDetails, setDashboardDetails] = useState("");
  const { data, setData } = useData();

  const [paginationState, setPaginationState] = useState(0);

  const saveScrollPosition = () => {
    if (containerRef.current) {
      sessionStorage.setItem(
        "dashboardScrollPosition",
        containerRef.current.scrollTop
      );
      sessionStorage.setItem("currentPageIndex", paginationState);
    }
  };

  const restoreScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("dashboardScrollPosition");
    if (scrollPosition && containerRef.current) {
      containerRef.current.scrollTop = parseInt(scrollPosition);
    }
    const currentPageIndex = sessionStorage.getItem("currentPageIndex");

    if (isDesktop && gridRef.current.api && currentPageIndex && dashboardData) {
      const currentPage =
        parseInt(sessionStorage.getItem("currentPageIndex")) || 0;
      gridRef.current.api.paginationGoToPage(currentPage);
    }
  };

  const onRowClicked = async (event) => {
    const rowData = event.data;
    await saveScrollPosition();
    navigate(`/core-routes/leads/reports/${rowData.whatsAppLeadId}`, {});
  };

  function deleteList(id) {
    let filtereddata = dashboardData.filter(
      (item, idx) => item.serialNo !== id
    );
    setDashboardData(filtereddata);
  }

  const onGridReady = (params) => {
    params.api.paginationSetPageSize(10);
  };

  const colDefs = [
    { field: "sNo", headerName: "S No" },

    {
      field: "whatsAppLeadId",
      headerName: "Lead ID",
    },
    { field: "customerName", headerName: "Name" },
    {
      field: "whatsappNumber",
      headerName: "Contact",
      valueFormatter: (params) => {
        const whatsappNumberData = params.value.replace("whatsapp:", "");
        return `${whatsappNumberData.slice(0, 3)} ${whatsappNumberData.slice(
          3,
          8
        )} ${whatsappNumberData.slice(8, 13)}`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: function (params) {
        const rowData = params.data;
        return (
          <span
            className={`${
              rowData.status === "NEW"
                ? "status-blue"
                : rowData.status === "ENQUIRY"
                ? "status-yellow"
                : rowData.status === "QUOTED"
                ? "status-green"
                : rowData.status === "CONTACT"
                ? "status-dark-yellow"
                : rowData.status === "PENDING"
                ? "status-grey"
                : rowData.status === "IGNORED"
                ? "status-red"
                : rowData.status === "SHIPPED"
                ? "status-dark-green"
                : rowData.status === "PAID"
                ? "status-dark-green"
                : rowData.status === "INVOICED"
                ? "status-dark-green"
                : null
            }`}
          >
            {rowData.status === "SHIPPED" && (
              <ImTruck
                style={{ marginRight: "5px", verticalAlign: "middle" }}
              />
            )}
            {rowData.status === "PAID" && (
              <BsCashCoin
                style={{ marginRight: "5px", verticalAlign: "middle" }}
              />
            )}
            {rowData.status}
          </span>
        );
      },
    },

    {
      field: "legitimacy",
      headerName: "Legitimacy",

      cellRenderer: function (params) {
        const rowData = params.data;
        return (
          <span
            style={{ width: "100px", alignItems: "center", display: "inline" }}
            className={`${
              rowData.legitimacy === "AUTO"
                ? "auto-status"
                : rowData.legitimacy === "MANUAL"
                ? "manual-status"
                : null
            }`}
          >
            {rowData.legitimacy}
          </span>
        );
      },
    },

    {
      field: "Actions",
      headerName: "Actions",

      cellRenderer: function (params) {
        const rowData = params.data;
        const handlenavigate = async () => {
          let url;
          if (rowData?.whatsAppLeadId) {
            url = `/core-routes/leads/chat/${rowData?.whatsAppLeadId}`;
          } else if (rowData?.whatsappNumber) {
            url = `/core-routes/leads/chat?whatsapp=${rowData?.whatsappNumber}`;
          }
          if (url) {
            navigate(`/core-routes/leads/chat/${rowData?.whatsAppLeadId}`);
            await saveScrollPosition();
          }
        };
        return (
          <span>
            <IoIosSend
              size={22}
              style={{
                color: "#396AFF",
                marginRight: "5px",
                cursor: "pointer",
              }}
            />
            <FaEyeSlash
              size={20}
              style={{ marginRight: "5px", cursor: "pointer" }}
            />
            <IoLogoWhatsapp
              size={22}
              style={{ color: "#25D366", cursor: "pointer" }}
              onClick={handlenavigate}
            />
          </span>
        );
      },
    },
  ];
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    paginationPageSizeSelector: false,
  };
  const [searchString, setSearchString] = useState("");
  useEffect(() => {
    if (data) {
      setDashboardDetails(data?.dashboardDetails);
      setDashboardData(data?.dashboardData);
    } else {
      axios.get(`${MODULE_URLS.GET_ALL_DASHBOARD_DATA}`).then((response) => {
        if (response.status === 200) {
          const responseData = response.data.data.allLeads;
          const dashboardDetails = response.data.data;
          setDashboardDetails(dashboardDetails);

          const rowDataWithSNo = responseData.map((rowData, index) => ({
            ...rowData,
            sNo: index + 1,
          }));
          setDashboardData(rowDataWithSNo);

          setData({
            dashboardDetails: dashboardDetails,
            dashboardData: rowDataWithSNo,
          });
        }
      });
    }
  }, [searchString]);

  const handleChange = (event) => {
    const searchString = event.target.value;
    setSearchString(searchString);
    if (searchString !== "") {
      searchAPI(searchString);
    } else {
    }
  };

  const searchAPI = (searchString) => {
    axios
      .get(`${MODULE_URLS.GET_LIST_BY_SEARCH_STRING}${searchString}`)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data.data;
          console.log(response, "respo");
          const dashboardDetails = response.data.data;
          setDashboardDetails(dashboardDetails);

          const rowDataWithSNo = responseData.map((rowData, index) => ({
            ...rowData,
            sNo: index + 1,
          }));
          setDashboardData(rowDataWithSNo);

          setData({
            dashboardDetails: dashboardDetails,
            dashboardData: rowDataWithSNo,
          });
        }
      });
  };

  useEffect(() => {
    if (dashboardDetails) {
      restoreScrollPosition();
    }
  }, [dashboardDetails]);

  const gridRef = useRef();

  const onPaginationChanged = (params) => {
    if (params.api) {
      const currentPageIndex = params.api.paginationGetCurrentPage();
      setPaginationState(currentPageIndex);
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);
  return (
    <>
      {isDesktop ? (
        <div
          ref={containerRef}
          className="dashboard-component"
          style={{
            overflowY: "auto",
            height: "100vh",
            paddingBottom: "110px",
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
          }}
        >
          <span
            className="leads-main-mobile-con"
            style={{ backgroundColor: "#F5F7FA" }}
          >
            <div className="dashboard-info-bar">
              <div className="dashboard-info-content">
                <div
                  className="dashboard-icon-div"
                  style={{ backgroundColor: "#E7EDFF" }}
                >
                  <img src={userIcon} alt="TotalLeads" />
                </div>
                <div className="dashboard-info-text-div">
                  <div className="dashboard-info-text-header">Total Leads</div>
                  <div className="dashboard-info-text-value">
                    {dashboardDetails?.totalLeads}
                  </div>
                </div>
              </div>
              <div className="dashboard-info-content">
                <div
                  className="dashboard-icon-div"
                  style={{ backgroundColor: "#FFF5D9" }}
                >
                  <img src={Briefcase} alt="TotalLeads" />
                </div>
                <div className="dashboard-info-text-div">
                  <div className="dashboard-info-text-header">Quotes Sent</div>
                  <div className="dashboard-info-text-value">
                    {dashboardDetails?.quotedLeads}
                  </div>
                </div>
              </div>
              <div className="dashboard-info-content">
                <div
                  className="dashboard-icon-div"
                  style={{ backgroundColor: "#FFE0EB" }}
                >
                  <img src={subtract} alt="TotalLeads" />
                </div>
                <div className="dashboard-info-text-div">
                  <div className="dashboard-info-text-header">
                    Leads Ignored
                  </div>
                  <div className="dashboard-info-text-value">
                    {dashboardDetails?.ignoredLeads}
                  </div>
                </div>
              </div>
              <div className="dashboard-info-content">
                <div
                  className="dashboard-icon-div"
                  style={{ backgroundColor: "#DCFAF8" }}
                >
                  <img src={tick} alt="TotalLeads" />
                </div>
                <div className="dashboard-info-text-div">
                  <div className="dashboard-info-text-header">
                    Leads Converted
                  </div>
                  <div className="dashboard-info-text-value">
                    {dashboardDetails?.convertedLeads}
                  </div>
                </div>
              </div>
            </div>
            <span className="d-flex justify-content-between">
              <div className="dashboard-header-content">Current Leads</div>
              <div className="search-container">
                <div className="search-icon">
                  <CiSearch size={20} />
                </div>
                <input
                  className="search-input"
                  type="text"
                  value={searchString}
                  onChange={handleChange}
                  placeholder="Search..."
                />
              </div>
            </span>
          </span>

          <div className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              rowData={dashboardData}
              columnDefs={colDefs}
              onRowClicked={onRowClicked}
              gridOptions={gridOptions}
              pagination={true}
              domLayout="autoHeight"
              paginationPageSize={10}
              onGridReady={onGridReady}
              onPaginationChanged={onPaginationChanged}
              defaultColDef={defaultColDef}
            />
          </div>
        </div>
      ) : (
        <div
          ref={containerRef}
          style={{
            marginTop: "15px",
            paddingBottom: "80px",
            overflowY: "auto",
            height: "100vh",
          }}
        >
          <div className="leads-main-mobile-con">
            <div className="mobile-leads-con-header">
              <span
                className="image-head-container col-3"
                style={{ borderRight: "1px solid #000000" }}
              >
                <span>
                  {" "}
                  <img src={userIcon} alt="TotalLeads" />
                </span>
                <span className="header-text-con">
                  {" "}
                  {dashboardDetails?.totalLeads}
                </span>
              </span>
              <span
                className="image-head-container col-3"
                style={{ borderRight: "1px solid #000000" }}
              >
                <span>
                  {" "}
                  <img src={Briefcase} alt="TotalLeads" />
                </span>
                <span className="header-text-con">
                  {" "}
                  {dashboardDetails?.quotedLeads}
                </span>
              </span>
              <span
                className="image-head-container col-3"
                style={{ borderRight: "1px solid #000000" }}
              >
                <span>
                  <img src={subtract} alt="TotalLeads" />
                </span>

                <span className="header-text-con">
                  {" "}
                  {dashboardDetails?.ignoredLeads}
                </span>
              </span>
              <span className="image-head-container col-3">
                <span>
                  {" "}
                  <img src={tick} alt="TotalLeads" />
                </span>
                <span className="header-text-con">
                  {" "}
                  {dashboardDetails?.convertedLeads}
                </span>
              </span>
            </div>
          </div>
          <div className="search-outer-container">
            <span
              style={{
                marginTop: "10px",
                fontWeight: "500",
                textWrap: "nowrap",
              }}
            >
              Current Leads
            </span>

            <div className="search-container">
              <div className="search-icon" style={{ top: "57%" }}>
                <CiSearch size={20} />
              </div>
              <input
                className="search-input-mobile"
                type="text"
                value={searchString}
                onChange={handleChange}
                placeholder="Search..."
              />
            </div>
          </div>

          <div className="outer-list-container ">
            {dashboardData &&
              dashboardData.length > 0 &&
              dashboardData.map((data, index) => {
                const whatsappNumber = data?.whatsappNumber.replace(
                  "whatsapp:",
                  ""
                );
                const countryCode = whatsappNumber.slice(0, 3);
                const remainingDigits = whatsappNumber.slice(3);
                const formattedNumber =
                  countryCode +
                  " " +
                  remainingDigits.match(/.{1,5}/g).join(" ");
                return (
                  <div
                    onClick={async () => {
                      navigate(
                        `/core-routes/leads/reports/${data?.whatsAppLeadId}`
                      );
                      await saveScrollPosition();
                    }}
                    key={index}
                    className="mobile-leads-con gap-3"
                    style={{
                      borderLeft:
                        data?.status === "NEW"
                          ? "5px solid #128064"
                          : data?.status === "ENQUIRING" ||
                            data?.status === "QUOTED" ||
                            data?.status === "CONTACTCREATED" ||
                            data?.status === "PENDING"
                          ? "5px solid #FBA500"
                          : "5px solid #DA1212",
                    }}
                  >
                    <div className="col-10">
                      <div className="row">
                        <div className="d-flex column gap-2">
                          <span style={{ fontWeight: "600", fontSize: "14px" }}>
                            {formattedNumber}
                          </span>
                          <span style={{ fontWeight: "300", fontSize: "14px" }}>
                            ({data?.customerName})
                          </span>
                        </div>
                        <div className="d-flex column gap-2">
                          <span
                            style={{
                              paddingLeft: "10px",
                              borderRadius: "5px",
                              alignItems: "center",
                              display: "flex",
                              fontSize: "12px",
                              height: "25px",
                              fontWeight: "500",
                            }}
                            className={`${
                              data?.status === "NEW"
                                ? "status-blue"
                                : data?.status === "ENQUIRY"
                                ? "status-yellow"
                                : data?.status === "QUOTED"
                                ? "status-green"
                                : data?.status === "CONTACT"
                                ? "status-dark-yellow"
                                : data?.status === "PENDING"
                                ? "status-grey"
                                : data?.status === "IGNORED"
                                ? "status-red"
                                : data?.status === "SHIPPED"
                                ? "status-dark-green"
                                : data?.status === "PAID"
                                ? "status-dark-green"
                                : data?.status === "INVOICED"
                                ? "status-dark-green"
                                : null
                            }`}
                          >
                            {data.status === "SHIPPED" && (
                              <ImTruck
                                style={{
                                  marginRight: "5px",
                                  verticalAlign: "middle",
                                }}
                              />
                            )}
                            {data.status === "PAID" && (
                              <BsCashCoin
                                style={{
                                  marginRight: "5px",
                                  verticalAlign: "middle",
                                }}
                              />
                            )}
                            {data?.status}
                          </span>
                          <span>
                            {data?.legitimacy === "MANUAL" && (
                              <span className="manual-status">Manual</span>
                            )}
                            {data?.legitimacy === "AUTO" && (
                              <span className="auto-status">Auto</span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-2"
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span>
                        {" "}
                        <IoLogoWhatsapp
                          onClick={async (e) => {
                            e.stopPropagation();
                            navigate(
                              `/core-routes/leads/chat/${data?.whatsAppLeadId}`
                            );
                            await saveScrollPosition();
                          }}
                          style={{
                            height: "30px",
                            width: "30px",
                            color: "#25D366",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
