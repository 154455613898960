import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { MODULE_URLS } from "../../constants/module-urls";
import "../Settings.css";
import KeywordAdd from "./KeywordAdd";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { MdOutlineDelete } from "react-icons/md";

function KeywordList() {
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);

  const onRowClicked = (event) => {
    const rowData = event.data;
  };

  const [colDefs] = useState([
    { field: "sNo", headerName: "S No" },
    { field: "keyword", headerName: "Keyword" },
    { field: "response", headerName: "Response" },
    {
      headerName: "Actions",
      cellRenderer: (params) => {
        console.log("Params in cellRenderer:", params);
        return (
          <span
            className="delete-icon"
            title="Delete"
            onClick={() => handleAlert(params.data.keywordId)}
          >
            <MdOutlineDelete size={20} />
          </span>
        );
      },
      width: 100,
      suppressMenu: true,
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const [keywordsData, setKeywordsData] = useState(null);

  const getTableData = () => {
    axios.get(`${MODULE_URLS.GET_ALL_KEYWORD_DETAILS}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data;
        const rowDataWithSNo = responseData.map((rowData, index) => ({
          ...rowData,
          sNo: index + 1,
        }));
        setKeywordsData(rowDataWithSNo);
      }
    });
  };
  useEffect(() => {
    getTableData();
  }, []);

  const handleAlert = (keywordId) => {
    Swal.fire({
      title: "Confirm Delete",
      html: "Are you sure you want to Delete this keyword?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(keywordId);
      }
    });
  };

  const handleDelete = (keywordId) => {
    axios
      .delete(`${MODULE_URLS.DELETE_KEYWORD_BY_ID}/${keywordId}`)
      .then((response) => {
        if (response.status === 200) {
          getTableData();
          toast.success("Keyword Deleted Successfully");
        } else {
          throw new Error("Failed to delete");
        }
      })
      .catch((error) => {
        console.error("Error deleting:", error);
      });
  };

  return (
    <div className="dashboard-component">
      <div className="profile-container-query-chat-screen">
        <div className="profile-container">
          <div className="profile-name-container-query-chat-screen">
            <span className="profile-name-text-query-chat-screen">
              Keywords
            </span>
          </div>
        </div>
        <div className="writing-parameter-list-content-inner-text">
          <button
            className="list-add-bttn"
            style={{ height: "auto" }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Add New Keywords
          </button>
        </div>
      </div>

      <div
        className="dashboard-table-content"
        style={{ width: "100%", height: "calc(100vh - 200px)" }}
      >
        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: "100%" }}
        >
          <AgGridReact
            rowData={keywordsData}
            columnDefs={colDefs}
            onRowClicked={onRowClicked}
            gridOptions={gridOptions}
          />
        </div>
      </div>
      <KeywordAdd
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </div>
  );
}

export default KeywordList;
