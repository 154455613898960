import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { MODULE_URLS } from "../../constants/module-urls";
import "../Settings.css";
import AddDefaultAnswers from "./AddDefaultAnswers";
import { MdOutlineDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function ListDefaultAnswers() {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);

  const [defaultAnswerData, setDefaultAnswerData] = useState(null);

  const getTableData = () => {
    axios.get(`${MODULE_URLS.GET_ALL_DEFAULT_RESPONSE}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data;
        const rowDataWithSNo = responseData.map((rowData, index) => ({
          ...rowData,
          sNo: index + 1,
        }));
        setDefaultAnswerData(rowDataWithSNo);
      }
    });
  };
  useEffect(() => {
    getTableData();
  }, [location.pathname]);

  const handleAlert = (responseId) => {
    Swal.fire({
      title: "Confirm Delete",
      html: "Are you sure you want to Delete this response?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(responseId);
      }
    });
  };

  const handleDelete = (responseId) => {
    axios
      .delete(`${MODULE_URLS.DELETE_DEFAULT_RESPONSE_BY_ID}/${responseId}`)
      .then((response) => {
        if (response.status === 200) {
          getTableData();
          toast.success("Response Deleted Successfully");
        } else {
          throw new Error("Failed to delete");
        }
      })
      .catch((error) => {
        console.error("Error deleting:", error);
      });
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const colDefs = [
    { field: "sNo", headerName: "S No" },
    { field: "responseText", headerName: "Default Response" },
    {
      headerName: "Actions",
      cellRenderer: (params) => {
        console.log("Params in cellRenderer:", params);
        return (
          <span
            className="delete-icon"
            title="Delete"
            onClick={() => handleAlert(params?.data?.responseId)}
          >
            <MdOutlineDelete size={20} />
          </span>
        );
      },
      width: 100,
      suppressMenu: true,
    },
  ];
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  return (
    <div className="dashboard-component">
      <div className="profile-container-query-chat-screen">
        <div className="profile-container">
          <div className="profile-name-container-query-chat-screen">
            <span className="profile-name-text-query-chat-screen">
              Default Response
            </span>
          </div>
        </div>
        <div className="writing-parameter-list-content-inner-text">
          <button
            className="list-add-bttn"
            style={{ height: "auto" }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            {" "}
            Add New Response
          </button>
        </div>
      </div>

      <div
        className="dashboard-table-content"
        style={{ width: "100%", height: "calc(100vh - 200px)" }}
      >
        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: "100%" }}
        >
          <AgGridReact
            rowData={defaultAnswerData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            gridOptions={gridOptions}
          />
        </div>
      </div>
      <AddDefaultAnswers
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </div>
  );
}

export default ListDefaultAnswers;
