import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { Formik, Form, FieldArray } from "formik";
import { useLocation } from "react-router-dom";
import { MODULE_URLS } from "../constants/module-urls";
import axios from "axios";

function OtherDetailsModal({ isOpen, onClose, onSubmit }) {
  const [selectedOption, setSelectedOption] = useState("");
  const location = useLocation();
  const [options, setOptions] = useState([]);
  useEffect(() => {
    axios.get(`${MODULE_URLS.GET_ALL_OTHER_DETAILS}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data;
        setOptions(responseData);
      }
    });
  }, [location.pathname]);
  useEffect(() => {
    setSelectedOption("");
  }, [isOpen]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleFormSubmit = () => {
    onSubmit(selectedOption);
    onClose();
  };
  const customModalStyles = {
    content: {
      height: "50vh",
      margin: "auto",
      padding: "10px 20px 0px 20px",
    },
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldReturnFocusAfterClose={false}
      style={{
        ...customModalStyles,
        content: {
          ...customModalStyles.content,
          width: "80%",
        },
      }}
    >
      <div className="modal-container">
        <div className="modal-header">
          <span className="modal-head-Text">Other Details</span>
          <IoClose
            color="#000000"
            size={"23px"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <div className="modal-body">
          <Formik
            initialValues={{ options: [] }}
            onSubmit={handleFormSubmit}
            enableReinitialize={true}
          >
            {({ values }) => (
              <Form>
                <FieldArray
                  name="options"
                  render={(arrayHelpers) => (
                    <div style={{ marginTop: "7px", padding: "10px" }}>
                      {options?.map((option, index) => (
                        <span
                          key={index}
                          className="option"
                          style={{
                            fontFamily: "sans-serif",
                            backgroundColor:
                              selectedOption === option?.responseText
                                ? "#f2f2f2"
                                : "transparent",
                          }}
                          onClick={() =>
                            handleOptionClick(option?.responseText)
                          }
                        >
                          <span className="circle">{index + 1}</span>{" "}
                          {option?.responseText}
                        </span>
                      ))}
                    </div>
                  )}
                />
              </Form>
            )}
          </Formik>
        </div>
        <div className="submit-button-container">
          <button
            type="submit"
            className="list-add-bttn"
            onClick={() => handleFormSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default OtherDetailsModal;
