import logo from "./logo.svg";
import "./App.css";
import Login from "./core/login/Login";
import { Route, Routes } from "react-router-dom";
import CoreRoutes from "./core/routes/CoreRoutes";
import { DataProvider } from "./DataProvider";

function App() {
  return (
    <div className="App">
      <DataProvider>
        <Routes >
          <Route  path="" element={<Login />} />

          {/* <Route path="/core-routes/*" element={<ProtectedRoute><CoreRoutes />  </ProtectedRoute>}></Route> */}
          <Route path="/core-routes/*" element={<CoreRoutes />}></Route>
        </Routes>
      </DataProvider>
    </div>
  );
}

export default App;
