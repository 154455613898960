import React from "react";
import { Link } from "react-router-dom";
import "./SideBar.css";
// import Logo from "../../assets/images/logo.png";
import Logo_desktop_view from "../../assets/images/logo_desktop_view.png";
import Logo1 from "../../assets/images/logo1.png";
import logo1_desktop_view from "../../assets/images/logo1_desktop_view.png";

import { IoSettings } from "react-icons/io5";
import DashboardLogo from "../../assets/image-components/DashboardLogo";
import LeadsLogo from "../../assets/image-components/LeadsLogo";
const SideBar = () => {
  return (
    <div className="sidebar sidebar-container d-none d-md-block ">
      <div className="sidebar-container">
        <div className="sidebar-logo-container">
          <img src={Logo_desktop_view} alt="logo1" className="sidebar-logo1" />
          
          <img src={logo1_desktop_view} alt="logo2" className="sidebar-logo2" height='50px'/>
          
        </div>
        <ul className="sidebar-nav">
          <li className="sidebar-item ">
            <Link to="/core-routes/dashboard" className="sidebar-link">
              <DashboardLogo/>
              <span className="sidebar-text">Dashboard</span>
            </Link>
          </li>
          <li className="sidebar-item ">
            <Link to="/core-routes/leads" className="sidebar-link">
              <LeadsLogo />
              <span className="sidebar-text">Leads</span>
            </Link>
          </li>
          <li className="sidebar-item ">
            <Link to="/core-routes/settings" className="sidebar-link">
              <IoSettings 
                style={{ width: "25px", height: "25px" }}
               />
              <span className="sidebar-text">Settings</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
