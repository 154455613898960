import "./Reports.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import Womenimage from "../../assets/images/Mask Group (1).png";
import editicon from "../../assets/images/Group 194.png";
import { IoIosArrowBack, IoIosSend } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import Select from "react-select";
import { RxTriangleDown } from "react-icons/rx";
import { isDesktop } from "react-device-detect";
import axios from "axios";
import { MODULE_URLS } from "../constants/module-urls";

function Reports() {
  const { id } = useParams();
  const location = useLocation();
  const whatsappNumber = new URLSearchParams(location.search).get("whatsapp");
  const navigate = useNavigate();
  const [Reports, setReports] = useState(null);
  const [InitialValues, setInitialValues] = useState({
    customerName: "",
    whatsappUserName: "",
    contactNumber: "",
    country: "",
    city: "",
    leadSource: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${MODULE_URLS.GET_CHAT_BY_ID}${id}`);
        const data = response?.data?.data;
        setReports(data);
        setInitialValues({
          customerName: data ? data.customerName : "",
          whatsappUserName: data ? data.waProfileName : "",
          contactNumber: data ? formatWhatsAppNumber(data.whatsappNumber) : "",
          country: data ? data.country : "",
          city: data ? data.city : "",
          leadSource: data ? data.leadSource : "",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);
  const formatWhatsAppNumber = (number) => {
    const whatsappNumberData = number?.replace("whatsapp:", "");
    return `${whatsappNumberData?.slice(0, 3)} ${whatsappNumberData?.slice(
      3,
      8
    )} ${whatsappNumberData?.slice(8, 13)}`;
  };
  const options = [
    { value: "whatsapp", label: "Whatsapp" },
    { value: "telegram", label: "Telegram" },
  ];
  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value === "whatsapp") {
      navigate(`/core-routes/leads/chat/${id}`, {});
    } else if (selectedOption.value === "telegram") {
    }
  };
  const whatsappNumberData = Reports?.whatsappNumber?.replace("whatsapp:", "");
  const formattedNumber = `${whatsappNumberData?.slice(
    0,
    3
  )} ${whatsappNumberData?.slice(3, 8)} ${whatsappNumberData?.slice(8, 13)}`;

  const openQuoteInNewTab = () => {
    window.open(
      "https://www.chennaihairsindia.com/index.php/home/get_quote",
      "_blank"
    );
  };

  const openPaymentInNewTab = () => {
    window.open("https://www.chennaihairsindia.com/payment/home", "_blank");
  };

  const validationSchema = Yup.object().shape({
    customerName: Yup.string(),
    whatsappUserName: Yup.string(),
    contactNumber: Yup.number(),
    country: Yup.string(),
    city: Yup.string(),
    leadSource: Yup.string(),
  });

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: validationSchema,
    onSubmit: () => {},
    enableReinitialize: true,
  });
  return (
    <>
      {isDesktop ? (
        <div
          className="reports-component"
          style={{
            overflowY: "auto",
            height: "100vh",
            paddingBottom: "90px",
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
          }}
        >
          <span>
            <div className="reports-component-tab-bar">
              <div className="reports-component-active-tab-bar">
                Lead Details
              </div>
              <div>Transactions</div>
              <div>Notes</div>
            </div>
          </span>
          <div className="reports-component-main-content">
            <div className="reports-component-profile-div">
              <div style={{ position: "relative" }}>
                <img
                  src={Womenimage}
                  alt="Women1"
                  style={{ borderRadius: "50%", width: "100%", height: "auto" }}
                />
                <img
                  src={editicon}
                  alt="Women1"
                  id="reports-component-profile-edit-icon"
                />
              </div>
              <div className="reports-component-actions-heading">Actions</div>
              <div className="reports-component-profile-button-div">
                <button onClick={openQuoteInNewTab}>
                  <IoIosSend
                    size={22}
                    style={{
                      cursor: "pointer",
                      color: "#396AFF",
                      marginRight: "5px",
                      position: "relative",
                      top: "-2px",
                    }}
                  />
                  Send Quote
                </button>
                <button onClick={openPaymentInNewTab}>
                  <RiMoneyDollarCircleFill
                    size={22}
                    style={{
                      cursor: "pointer",
                      color: "#396AFF",
                      marginRight: "5px",
                      position: "relative",
                      top: "-2px",
                    }}
                  />
                  Send Payment link
                </button>
                <button>
                  <MdCancel
                    size={22}
                    style={{
                      cursor: "pointer",
                      color: "#396AFF",
                      marginRight: "5px",
                      position: "relative",
                      top: "-2px",
                    }}
                  />
                  Not legitimate
                </button>
                <button
                  onClick={() => {
                    navigate(`/core-routes/leads/chat/${id}`, {});
                  }}
                >
                  <IoLogoWhatsapp
                    size={18}
                    style={{
                      color: "#396AFF",
                      marginRight: "5px",
                      position: "relative",
                      top: "-2px",
                    }}
                  />
                  Chat
                </button>
              </div>
            </div>
            <div className="reports-component-input-div">
              <div style={{ display: "flex" }}>
                <div className="device-input-div">
                  <label>Customer name </label>
                  <input
                    name="customerName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customerName}
                  ></input>
                </div>

                <div className="device-input-div">
                  <label>WhatsApp Username</label>
                  <input
                    name="whatsappUserName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.whatsappUserName}
                  ></input>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="device-input-div">
                  <label>Contact Number</label>
                  <input
                    name="contactNumber"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactNumber}
                  ></input>
                </div>

                <div className="device-input-div">
                  <label>Country</label>
                  <input
                    name="country"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                  ></input>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="device-input-div">
                  <label>City</label>
                  <input
                    name="city"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  ></input>
                </div>

                <div className="device-input-div">
                  <label>Lead Source</label>
                  <input
                    name="leadSource"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.leadSource}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: "10px" }}>
          <div className="report-head-mobile">
            <IoIosArrowBack
              onClick={() => navigate(-1)}
              style={{ color: "#9D2764" }}
            />
            <span style={{ fontWeight: "600" }}>{formattedNumber}</span>
            <span style={{ fontWeight: "300" }}>
              {`(${Reports?.waProfileName})`}
            </span>
          </div>

          <div className="reports-component-tab-bar-mobile">
            <div className="reports-component-active-tab-bar-mobile">
              Lead Details
            </div>
            <div>Transactions</div>
            <div>Notes</div>
          </div>

          <div
            className="reports-component-main-content"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}
            >
              <Select
                id="actions"
                options={options}
                isSearchable={false}
                classNamePrefix="react-select"
                onChange={handleSelectChange}
                components={{
                  DropdownIndicator: () => <RxTriangleDown color="#000000" />,
                  IndicatorSeparator: null,
                }}
                placeholder="Actions"
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </div>
            <div className="reports-component-input-div">
              <div style={{ display: "flex" }}>
                <div className="device-input-div-mobile">
                  <label>Customer name </label>
                  <input
                    name="customerName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customerName}
                  ></input>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="device-input-div-mobile">
                  <label>WhatsApp Username</label>
                  <input
                    name="whatsappUserName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.whatsappUserName}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Reports;
