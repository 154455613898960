import "./Chat.css";
import userIcon from "../../assets/images/user.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack, IoMdSend } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import DefaultAnswerModal from "./DefaultAnswerModal";
import OtherDetailsModal from "./OtherDetailsModal";
import { MODULE_URLS } from "../constants/module-urls";
import { toast } from "react-toastify";
import PlayIcon from "../../assets/image-components/PlayIcon";
import MessageIcon from "../../assets/image-components/MessageIcon";
import AttachmentIcon from "../../assets/image-components/AttachmentIcon";
import Div100vh from "react-div-100vh";
import UploadIcon from "../../assets/image-components/UploadIcon";
import { RiCloseCircleFill } from "react-icons/ri";
import axios from "axios";
import { ImAttachment } from "react-icons/im";
function Chat() {
  const { id } = useParams();
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const messageContainerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [attachmentFileName, setAttachmentFileName] = useState(null);
  const [otherDetailOpen, setOtherDetailsOpen] = useState(false);
  const [sendMessageText, setSendMessageText] = useState("");
  const [chatData, setChatData] = useState(null);
  const whatsAppNumber = chatData?.whatsappNumber;
  const [loadingFaq, setLoadingFaq] = useState(false);

  const scrollBottom = () => {
    const container = scrollContainerRef.current;
    const messager = scrollContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight + messager.scrollHeight;
    }
  };
  const fetchData = async () => {
    if (id) {
      const response = await axios.get(`${MODULE_URLS.GET_CHAT_BY_ID}${id}`);
      const data = response?.data?.data;
      setChatData(data);
      scrollBottom();
    }
  };
  useEffect(() => {
    if (chatData) {
      scrollBottom();
    }
  }, [chatData]);

  const SendMessage = async () => {
    if (sendMessageText !== "") {
      const requestData = {
        to: whatsAppNumber,
        message: sendMessageText,
      };
      const response = await axios.post(
        `${MODULE_URLS.POST_CHAT_CONVERSATION}`,
        requestData
      );
      if (response.status === 200) {
        setSendMessageText("");
        fetchData();
      }
    } else if (attachment) {
      const fileExtension = attachmentFileName.split(".").pop().toLowerCase();
      let messageContent;

      if (["jpg", "jpeg", "png", "gif", "bmp"].includes(fileExtension)) {
        messageContent = `<img src="${attachment}" alt="${attachmentFileName}" />`;
      } else if (["mp4"].includes(fileExtension)) {
        messageContent = `<video> <source src="${attachment}" type="video/mp4"> </video>`;
      } else {
        messageContent = `<a target="_blank" rel="noreferrer" href="${attachment}">${attachmentFileName}</a>`;
      }

      const requestData = {
        to: whatsAppNumber,
        mediaURL: attachment,
        message: messageContent,
      };

      const response = await axios.post(
        `${MODULE_URLS.POST_CHAT_CONVERSATION}`,
        requestData
      );
      if (response.status === 200) {
        setSendMessageText("");
        setAttachment(null);
        setAttachmentFileName(null);
        setLoading(false);
        fetchData();
      } else {
        toast.error(response.error.message);
        setLoading(false);
      }
    }
  };

  const attachFile = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    setSendMessageText("");
    const maxSizeInBytes = 50 * 1024 * 1024;
    if (file?.size > maxSizeInBytes) {
      toast.error("Upload the document below 50 MB");
      setLoading(false);
    } else {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(
          `${MODULE_URLS.UPLOAD_DOCUMENT_VIA_CHAT}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          setAttachmentFileName(file?.name);
          setAttachment(response.data.data);
          setLoading(false);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        toast.error("An error occurred while uploading the document");
      } finally {
        setLoading(false);
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setOtherDetailsOpen(false);
  };

  function sendFAQs() {
    setLoadingFaq(true);
    const requestData = {
      to: whatsAppNumber,
      message: "Sending FAQs",
    };
    axios.post(`${MODULE_URLS.SEND_ALL_FAQS}`, requestData).then((response) => {
      if (response.status === 200) {
        toast.success("“Auto reply/response resumed”");
        setLoadingFaq(false);
      } else {
        setLoadingFaq(false);
      }
    });
  }

  const defaultAnswerSubmit = (selectedOption) => {
    setSendMessageText(selectedOption);
  };

  const otherDetailSubmit = (selectedOption) => {
    setSendMessageText(selectedOption);
  };

  function formatDateString(inputDateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(inputDateString).toLocaleDateString(
      "en-US",
      options
    );

    return formattedDate;
  }

  useEffect(() => {
    setSendMessageText("");
    const fetchDataAndUpdate = async () => {
      if (id) {
        const response = await axios.get(`${MODULE_URLS.GET_CHAT_BY_ID}${id}`);
        const data = response?.data?.data;
        if (
          chatData === null ||
          chatData === undefined ||
          chatData?.conversation?.length != data?.conversation?.length
        ) {
          setChatData(data);
          scrollBottom();
        }
      }
    };
    fetchDataAndUpdate();
    const intervalId = setInterval(fetchDataAndUpdate, 1000);
    return () => clearInterval(intervalId);
  }, [id, chatData]);

  const whatsappNumberData = whatsAppNumber?.replace("whatsapp:", "");
  const formattedNumber = `${whatsappNumberData?.slice(
    0,
    3
  )} ${whatsappNumberData?.slice(3, 8)} ${whatsappNumberData?.slice(8, 13)}`;

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      SendMessage();
    }
  };
  function getVideoSrc(videoTag) {
    const srcRegex = /src="([^"]+)"/;
    const match = videoTag.match(srcRegex);
    return match ? match[1] : "";
  }

  console.log(chatData?.conversation, "chatData?.conversation");
  return (
    <Div100vh>
      <div className="chat-component">
        <div className="chat-content">
          <div className="query-feedback-right-container">
            <div className="profile-container-query-chat-screen-Container">
              <div className="profile-container">
                <IoIosArrowBack
                  size={25}
                  onClick={() => navigate(-1)}
                  style={{
                    color: "#ffffff",
                  }}
                />
                <img
                  alt="img"
                  src={userIcon}
                  className="profile-image-query-chat-screen"
                />

                <div className="profile-name-container-query-chat-screen">
                  <span
                    className="profile-name-text-query-chat-screen"
                    style={{ color: "#ffffff" }}
                  >
                    {chatData?.waProfileName}
                  </span>
                  <span
                    className="profile-student-id-text-query-chat-screen"
                    style={{ color: "#ffffff" }}
                  >
                    {formattedNumber}
                  </span>
                </div>
              </div>
            </div>

            <div
              className="query-chat-container"
              id="query-chat-container"
              ref={scrollContainerRef}
            >
              {chatData?.conversation?.map((message, idx) => (
                <div ref={messageContainerRef}>
                  <div
                    key={idx}
                    className={`${
                      message.senderId === 0
                        ? "send-message-text-container"
                        : "receive-message-text-container"
                    }`}
                  >
                    <div
                      className={`${
                        message.senderId === 0
                          ? "send-message-text-inner-container"
                          : "receive-message-text-inner-container"
                      }`}
                      style={
                        message.message?.trim()?.startsWith("<img src=")
                          ? {
                              minWidth: "unset",
                              background: "transparent",
                            }
                          : {}
                      }
                    >
                      <span
                        className={`${
                          message.senderId === 0
                            ? "send-message-text"
                            : "receive-message-text"
                        }`}
                      >
                        {message.message
                          ?.trim()
                          ?.startsWith(
                            '<a target="_blank" rel="noreferrer" href='
                          ) ? (
                          <span className="send-receive-message-text">
                            Uploaded File :{"  "}
                            <ImAttachment color="#000000" />
                            <span
                              className="attachment-color-query"
                              dangerouslySetInnerHTML={{
                                __html: message.message,
                              }}
                            />
                          </span>
                        ) : message.message?.trim()?.startsWith("<img src=") ? (
                          <span className="send-receive-message-text">
                            <span
                              className="attachment-image"
                              dangerouslySetInnerHTML={{
                                __html: message.message,
                              }}
                            />
                          </span>
                        ) : message.message?.trim()?.startsWith("<video") ? (
                          <div className="video-player-container">
                            <video
                              className="attachment-video"
                              controls
                              style={{ width: "100%", height: "100%" }}
                            >
                              <source
                                src={getVideoSrc(message.message)}
                                type="video/mp4"
                              />{" "}
                            </video>
                          </div>
                        ) : (
                          <span className="send-receive-message-text">
                            {message.message}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`${
                      message.senderId === 0
                        ? "send-message-time-text"
                        : "receive-message-time-text"
                    }`}
                  >
                    {formatDateString(message?.date)}
                  </div>
                </div>
              ))}
            </div>

            <div className="query-chatlist-message-input-container">
              <div className="writing-parameter-list-content-inner-text">
                {loadingFaq ? (
                  <div className="faq-loader">
                    <div
                      className="lds-ring"
                      style={{ width: "10px", height: "10px" }}
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <span style={{ cursor: "pointer" }}>
                    <PlayIcon onClick={sendFAQs} />
                  </span>
                )}

                <span style={{ cursor: "pointer" }}>
                  <AttachmentIcon onClick={() => setIsModalOpen(true)} />
                </span>
                <span style={{ cursor: "pointer" }}>
                  <MessageIcon
                    onClick={() => setOtherDetailsOpen(true)}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </div>
              <div className="container-chat-text">
                <textarea
                  id="query-chatlist-message-input"
                  className="query-chatlist-message-input"
                  placeholder={`${attachmentFileName ? "" : "Type a message"}`}
                  value={sendMessageText}
                  disabled={attachmentFileName}
                  onChange={(event) => {
                    setSendMessageText(event.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
                {attachmentFileName && (
                  <span className="attachement-text-container">
                    <span className="close-attachment-icon">
                      <RiCloseCircleFill
                        onClick={() => {
                          setAttachment(null);
                          setLoading(false);
                          setAttachmentFileName(null);
                        }}
                      />
                    </span>
                    <a
                      href={attachment}
                      target="_blank"
                      rel="noreferrer"
                      className="attachement-text"
                    >
                      {attachmentFileName}
                    </a>
                  </span>
                )}
                <label
                  htmlFor="fileInput"
                  className="query-chatlist-message-attach-input-icon"
                >
                  <UploadIcon />
                </label>
                <input
                  onChange={attachFile}
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                />
                <button
                  className="chat-message-input-send-button"
                  onClick={() => SendMessage()}
                  disabled={loading || (!sendMessageText && !attachment)}
                >
                  {loading ? (
                    <div
                      className="lds-ring"
                      style={{ width: "10px", height: "10px" }}
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <IoMdSend color="#ffffff" size={"25px"} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <DefaultAnswerModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={defaultAnswerSubmit}
        />
        <OtherDetailsModal
          isOpen={otherDetailOpen}
          onClose={closeModal}
          onSubmit={otherDetailSubmit}
        />
      </div>
    </Div100vh>
  );
}

export default Chat;
