function PlayIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#ACBFA4" />
      <g clip-path="url(#clip0_185_143)">
        <path
          d="M21.5 20.3889V14L33 20.3889L21.5 26.7778V20.3889ZM21.5 20.3889L10 26.7778V14L21.5 20.3889Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_185_143">
          <rect
            width="29.3333"
            height="29.3333"
            fill="white"
            transform="matrix(0 -1 1 0 4.3335 33.6667)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PlayIcon;
