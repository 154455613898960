import axios from "axios";
import sessionstorageService from "./sessionstorage.service";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionstorageService.getToken();
    if (token) {
      config.headers["Authorization"] = ` ${token}`;
    }
    return config;
  },
  (error) => {
    sessionstorageService.isLoading("false");
    return Promise.reject(error);
  }
);

export const axiosService = {
  get: (url, params) => {
    sessionstorageService.isLoading("true");
    return axiosInstance.get(url, { params }).finally(() => {
      sessionstorageService.isLoading("false");
    });
  },
 
  post: (url, data) => {
    sessionstorageService.isLoading("true");
    return axiosInstance.post(url, data).finally(() => {
      sessionstorageService.isLoading("false");
    });
  },

 


  put: (url, data) => {
    sessionstorageService.isLoading("true");
    return axiosInstance.put(url, data).finally(() => {
      sessionstorageService.isLoading("false");
    });
  },



  delete: (url) => {
    sessionstorageService.isLoading("true");
    return axiosInstance.delete(url).finally(() => {
      sessionstorageService.isLoading("false");
    });
  },
};
