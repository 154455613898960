import { Field, Formik, Form, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { MODULE_URLS } from "../../constants/module-urls";
import { toast } from "react-toastify";

function AddDefaultAnswers({ open, onClose }) {
  const initialValues = {
    defaultAnswer: "",
  };

  const onSubmit = async (values) => {
    const defaultResponse = {
      responseText: values.defaultAnswer,
    };
    const response = await axios.post(
      `${MODULE_URLS.ADD_DEFAULT_RESPONSE_TEXT}`,
      defaultResponse
    );
    if (response.status === 200) {
      toast.success("Default Response Added Successfully!");
      onClose();
    }
  };

  const customModalStyles = {
    content: {
      width: "40%",
      height: "30vh",
      margin: "auto",
      padding: "10px 20px",
      zIndex: "",
    },
  };
  return (
    <Modal
      isOpen={open}
      shouldReturnFocusAfterClose={false}
      style={{
        ...customModalStyles,
        content: {
          ...customModalStyles.content,
          width: "80%",
        },
      }}
    >
      <div>
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #000000",
          }}
        >
          <span className="modal-head-Text">Add Default Response</span>
          <IoClose
            color="#000000"
            size={"23px"}
            onClick={() => {
              onClose();
            }}
          />
        </span>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values }) => (
            <Form>
              <div
                style={{
                  paddingBottom: "5px",
                }}
              >
                <div className="create-financial-main-input-container">
                  <div
                    className="create-admission-inner-first-input-container"
                    style={{ width: "100%", marginTop: "10px" }}
                  >
                    <div className="label-text-active">
                      {" "}
                      Enter Default Response
                    </div>
                    <Field
                      name="defaultAnswer"
                      style={{
                        width: "100%",
                        padding: "5px 10px",
                        height: "50px",
                        marginTop: "5px",
                        borderRadius: "5px",
                      }}
                    />
                    <ErrorMessage
                      name="defaultAnswer"
                      component="div"
                      className="add-staff-error"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{ position: "absolute", bottom: "20px", width: "auto" }}
              >
                <div className="d-flex align-items-center justify-content-end gap-3">
                  <button
                    className="list-add-bttn"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="list-add-bttn">
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
export default AddDefaultAnswers;
