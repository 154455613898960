import { AppConfig } from "../../env.development";

export const MODULE_URLS = {
  GET_ALL_DASHBOARD_DATA: `${AppConfig.api_url}v1/all`,
  POST_CHAT_CONVERSATION: `${AppConfig.api_url}api/WA/send`,
  GET_CHAT_BY_ID: `${AppConfig.api_url}v1/`,
  SEND_ALL_FAQS: `${AppConfig.api_url}api/WA/send/faq`,
  UPDATE_NOT_LEGITIMACY_STATUS: `${AppConfig.api_url}v1/legitimacy/`,
  ADD_DEFAULT_RESPONSE_TEXT: `${AppConfig.api_url}api/lookup/v1/response`,
  GET_ALL_DEFAULT_RESPONSE: `${AppConfig.api_url}api/lookup/v1/response/all`,
  ADD_OTHER_DETAIL_TEXT: `${AppConfig.api_url}api/lookup/v1/detail`,
  GET_ALL_OTHER_DETAILS: `${AppConfig.api_url}api/lookup/v1/detail/all`,
  ADD_KEYWORD_TEXT: `${AppConfig.api_url}api/lookup/v1/keyword`,
  GET_ALL_KEYWORD_DETAILS: `${AppConfig.api_url}api/lookup/v1/keyword/all`,
  UPLOAD_DOCUMENT_VIA_CHAT: `${AppConfig.api_url}api/lookup/v1/upload`,
  DELETE_DEFAULT_RESPONSE_BY_ID: `${AppConfig.api_url}api/lookup/v1`,
  DELETE_OTHER_DETAILS_BY_ID: `${AppConfig.api_url}api/lookup/v1/other-details`,
  DELETE_KEYWORD_BY_ID: `${AppConfig.api_url}api/lookup/v1/update-keyword`,
  GET_LIST_BY_SEARCH_STRING: `${AppConfig.api_url}v1/search/`,
};
