function UploadIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.08004 15.4774C0.236824 13.4756 0.271178 10.2475 2.12376 8.25301L8.74077 1.12892C10.1387 -0.376241 12.4111 -0.376373 13.8092 1.12892C15.1938 2.61954 15.1954 5.02461 13.8092 6.51698L8.04115 12.721C7.09936 13.735 5.56062 13.7208 4.63479 12.6879C3.74284 11.6928 3.77145 10.1156 4.68059 9.13674L9.21506 4.26137C9.41007 4.05172 9.72963 4.04814 9.92881 4.25336L10.65 4.99642C10.8492 5.20168 10.8526 5.53802 10.6576 5.74767L6.12359 10.6226C5.968 10.7901 5.95841 11.0684 6.10315 11.2299C6.24106 11.3838 6.45788 11.3863 6.59803 11.2354L12.3661 5.03138C12.9848 4.36526 12.9848 3.28075 12.3658 2.61426C11.7604 1.96258 10.7898 1.96225 10.1843 2.61426L3.56722 9.73831C2.4706 10.919 2.45369 12.8302 3.52965 13.9987C4.60252 15.1639 6.33674 15.1654 7.4116 14.0082L12.8393 8.16449C13.0342 7.95468 13.3538 7.95089 13.5531 8.15599L14.2748 8.89854C14.4741 9.10364 14.4777 9.43998 14.2828 9.64979L8.85509 15.4935C6.97573 17.5168 3.93577 17.4928 2.08004 15.4774Z"
        fill="#757a79"
      />
    </svg>
  );
}
export default UploadIcon;
