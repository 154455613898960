import { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./Login.css";
import sessionService from "../../services/sessionstorage.service";
// import { Auth } from "../../Services/auth.service"
/*import { User } from "../../Services/user.service"*/
// import { statusCode } from "../../Constant/CommonConstant"
function Login() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      LoginUser(values.email, values.password);
    },
  });

  async function LoginUser(username, password) {
    if (username === "midfin@gmail.com" && password === "midfin123") {
      navigate("/core-routes/dashboard");
    }
  }

  return (
    <div
      className="login-main-div"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div className="iiot-circle"></div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "700",
              color: "rgba(255, 255, 255, 1)",
              marginLeft: "10px",
            }}
          >
            IIoT Dashboard
          </p>
        </div>
        <div className="login-container">
          <form onSubmit={formik.handleSubmit}>
            <div style={{ marginTop: "20px" }}>
              <h3
                style={{
                  paddingBottom: "15px",
                  fontSize: "24px",
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                Sign In
              </h3>
              <p style={{ paddingBottom: "25px" }} className="sigin-text">
                Welcome back! Please enter your details
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "15px",
                position: "relative",
              }}
            >
              <label className="login-label-1">Username</label>
              <input
                className="login-input"
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error-message-1">{formik.errors.email}</div>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "15px",
                position: "relative",
              }}
            >
              <label className="login-label-2">Password</label>
              <input
                className="login-input"
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error-message-2">{formik.errors.password}</div>
              ) : null}
              {showPassword ? (
                <AiFillEye
                  className="login-eye-image"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <AiFillEyeInvisible
                  className="login-eye-slash-image"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>

            <div style={{ paddingBottom: "30px" }}>
              <button
                className="login-button"
                type="submit"
                disabled={!formik.isValid}
                style={{ marginTop: "20px" }}
              >
                Log In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
