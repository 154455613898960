function LeadsLogo(color) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="15" r="6.66667" fill="#9D2764" />
      <circle cx="28.3334" cy="15" r="5" fill="#9D2764" />
      <circle cx="11.6666" cy="15" r="5" fill="#9D2764" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.2808 30H33.8951C34.4867 30 34.941 29.492 34.8236 28.9121C34.3665 26.654 32.8462 21.6667 28.3334 21.6667C26.8566 21.6667 25.7002 22.2008 24.7968 22.9986C27.3097 24.6289 28.609 27.4837 29.2808 30Z"
        fill="#9D2764"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2033 22.9986C14.2998 22.2008 13.1434 21.6667 11.6666 21.6667C7.15386 21.6667 5.6335 26.654 5.17641 28.9121C5.05904 29.492 5.51331 30 6.10493 30H10.7192C11.391 27.4837 12.6903 24.6289 15.2033 22.9986Z"
        fill="#9D2764"
      />
      <path
        d="M20 23.3333C26.7065 23.3333 28.0157 29.8102 28.2713 32.3389C28.3268 32.8884 27.8856 33.3333 27.3333 33.3333H12.6666C12.1143 33.3333 11.6731 32.8884 11.7286 32.3389C11.9842 29.8102 13.2934 23.3333 20 23.3333Z"
        fill="#9D2764"
      />
    </svg>
  );
}
export default LeadsLogo;
