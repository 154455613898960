/// <reference path="../signup/signup.jsx" />

import React, { useState } from "react";
import "./CoreRoutes.css";
import { Route, Routes, useLocation } from "react-router-dom";
import SideBar from "../sidebar/SideBar";
import Header from "../header/Header";
import Dashboard from "../../module/dashboard/Dashboard";
import Reports from "../../module/Reports/Reports";

import { useEffect } from "react";
import Chat from "../../module/chat/Chat";
import SettingsLookupNavbar from "../../module/settings/SettingsLookupNavbar";

function CoreRoutes() {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setWindowWidth(false);
      } else if (window.innerWidth >= 576) {
        setWindowWidth(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={
        windowWidth
          ? { display: "flex", flexDirection: "row" }
          : {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }
      }
    >
      {windowWidth && (
        <div>
          <SideBar />
        </div>
      )}

      <div className="Right-side-component">
        {!location.pathname.includes("/core-routes/leads/chat") && (
          <div>
            <Header />
          </div>
        )}

        {/* <div className="content-div"> */}
        <div
          className={
            !windowWidth &&
            location.pathname.includes("/core-routes/leads/chat")
              ? "content-div whatsapp-mobile-view"
              : "content-div"
          }
        >
          <Routes>
            <Route path="leads" element={<Dashboard />} />
            <Route path="leads/reports/:id" element={<Reports />} />
            <Route path="leads/chat/:id" element={<Chat />} />
            <Route path="settings" element={<SettingsLookupNavbar />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default CoreRoutes;
