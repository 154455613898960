function MessageIcon({onClick}) {
  return (
    <svg
    onClick={onClick}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#ACBFA4" />
      <path
        d="M23 15H27C27.5523 15 28 15.4477 28 16V27L24.667 24.231C24.4875 24.0818 24.2608 24 24.0273 24H16C15.4477 24 15 23.5523 15 23V20M23 15V12C23 11.4477 22.5523 11 22 11H11C10.4477 11 10 11.4477 10 12V23.0003L13.333 20.2308C13.5125 20.0817 13.7392 20 13.9727 20H15M23 15V19C23 19.5523 22.5523 20 22 20H15"
        stroke="#232323"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export default MessageIcon;
