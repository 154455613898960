function AttachmentIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#ACBFA4" />
      <path
        d="M16.3312 22.7389L22.7383 16.3319M13.929 18.7343L12.3272 20.336C10.5579 22.1053 10.5574 24.974 12.3267 26.7433C14.096 28.5126 16.9653 28.5121 18.7346 26.7428L20.3347 25.1412M18.7336 13.9286L20.3353 12.3268C22.1046 10.5575 24.9728 10.5578 26.7421 12.3271C28.5113 14.0964 28.5112 16.9649 26.742 18.7342L25.1411 20.3359"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export default AttachmentIcon;
